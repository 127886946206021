// Step 1: Import React
import React from 'react'
import Layout from '../components/layout'
import {
  received,
  divider
} from '../components/layout.module.css'

const IndexPage = () => {
  
  return (
    <Layout pageTitle="Hello!">
      <h2>On this MOST beloved of days: adventures, oddities, &amp; scholarly pursuits will be delivered to the curious, the creative, &amp; the kind.</h2>
      <form
        action="https://buttondown.email/api/emails/embed-subscribe/itspostday"
        method="post"
        className="embeddable-buttondown-form"
      >
      <p className={received}>We celebrated the first Post Day on February 14th, 2022, and there was much rejoicing! The sender of this mail decided to learn to write fiction for the second Post Day (and did not realize how arduous of a task this would prove to be), so we're hoping we will be able to celebrate soon, if not quite on the one-year mark that was the original goal.</p>
      <p className={received}>To receive an invitation (and to receive gifts from the Post Day you missed!), the unicorn and I would be delighted to know more about you.</p>
              
              <p className={divider}>❦</p>
        <label htmlFor="firstName">What's your first name?</label>
        <input 
          type="text" 
          name="metadata__firstName" 
          id="first-name" 
        />
        <label htmlFor="bd-email">What's your email?</label>
        <input 
          type="email" 
          name="email" 
          id="bd-email"
        />
        <label htmlFor="pronouns">What are your pronouns?</label>
        <input 
          type="text"
          name="metadata__pronouns"
          id="pronouns"
        />
        <label htmlFor="curious-about">What are you curious about right now?</label>
        <textarea 
          name="metadata__curiousAbout" 
          id="curious-about" >
        </textarea>
        <label htmlFor="feels-alive">What's making you come alive right now?</label>
        <textarea 
          name="metadata__feelsAlive" 
          id="feels-alive" >
        </textarea>
        <label htmlFor="project">Is there anything you've made or are making that you're excited about? (links welcome)</label>
        <textarea 
          name="metadata__project" 
          id="project">
        </textarea>
        <label htmlFor="fabulous-person">Who do you think is fabulous, living or not, online or off?</label>
        <input 
          type="text" 
          name="metadata__fabulousPerson" 
          id="fabulous-person" 
        />
        <input type="submit" value="Deliver adventures" />
        <input type="hidden" name="tag" value="2️⃣" />
      </form>
      <footer><a href="https://sarahavenir.com">Who is this mail coming from?</a></footer>
    </Layout>
  )
}

export default IndexPage